<template>
  <div v-loading="showLoading">
    <div class="type-box">
      <div
        v-for="(item, index) in [
          '审核问题点',
          '自查自纠问题点',
          '7s不适用',
          /*'风控不符合',*/
          '审核计划',
        ]"
        :key="index"
        class="_TypeBox"
        :class="{
          action: typeBtnIndex == index,
          indexColor: index == 3,
        }"
        @click="changeTypeBtn(index)"
        style="width: 120px; margin-right: 20px"
      >
        <img
          v-if="index == 3"
          style="width: 18px; height: 18px; margin-right: 5px"
          src="../../assets/images/Examine_img/Stat...png"
        />
        {{ item }}
      </div>
    </div>
    <div class="type-box">
      <div class="_Select">
        <span style="width: 60px !important">公司：</span>
        <el-select
          v-model="compy"
          clearable
          placeholder="请选择"
          @change="changCompy"
        >
          <el-option
            v-for="item in $store.state.companyList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="_Select" style="width: 180px">
        <span>年：</span>
        <el-select
          v-model="currentYear"
          @change="changYear"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in yearsList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="block" style="margin-left: 35px">
        <span class="demonstration">时间：</span>
        <el-date-picker
          style="width: 130px"
          v-model="currentDay1"
          @change="changDay"
          type="date"
          placeholder="开始时间"
        >
        </el-date-picker>
        <span> 至 </span>
        <el-date-picker
          style="width: 130px"
          v-model="currentDay2"
          @change="changDay"
          type="date"
          placeholder="结束时间"
        >
        </el-date-picker>
      </div>
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        v-if="typeBtnIndex == 0"
        @click="getExcelData"
        >导出</el-button
      >
    </div>
    <!-- 现场检查 -->
    <!-- <el-table
      id="table"
      v-if="typeBtnIndex == 0"
      :data="tableData.slice(start, end)"
      :height="elementHeight"
      style="width: 100%"
      @row-dblclick="rowDblclick1"
      @row-click="rowClick"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
      >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column label="公司" prop="compName"> </el-table-column>
      <el-table-column label="检查区域" prop="auditAreaName"> </el-table-column>
      <el-table-column label="备注" prop="remark"> </el-table-column>
      <el-table-column label="负责人" prop="titleCount"> </el-table-column>
      <el-table-column
        prop="titleCount"
        label="抽取问题数"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="status" v-show="scope.row.status === 0">
            <div class="statusbox"></div>
            未抽题
          </span>
          <span class="status" v-show="scope.row.status === 1"
            ><div style="background: #7fc7fa" class="statusbox"></div>
            已抽题</span
          >
          <span class="status" v-show="scope.row.status === 2"
            ><div class="statusbox1"></div>
            已完成</span
          >
          <span class="status" v-show="scope.row.status === 3"
            ><div class="statusbox"></div>
            整改完成</span
          >
          <span class="status" v-show="scope.row.status === 4"
            ><div style="background: #f2729c" class="statusbox"></div>
            未完成</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table> -->

    <!-- 文件落地 -->
    <!-- <el-table
      v-if="typeBtnIndex == 1"
      :data="tableData.slice(start, end)"
      tooltip-effect="dark"
      style="width: 100%"
      :height="elementHeight"
      @row-dblclick="rowDblclick2"
      @row-click="rowClick"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
      >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column label="岗位" prop="postName" width="120">
      </el-table-column>
      <el-table-column prop="companyName" label="所属公司" width="120">
      </el-table-column>

      <el-table-column
        label="抽取问题数"
        prop="titleCount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未抽题</span>
          <span v-show="scope.row.status === 1">已抽题</span>
          <span v-show="scope.row.status === 2">完成</span>
          <span v-show="scope.row.status === 3">整改完成</span>
          <span v-show="scope.row.status === 4">未完成</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table> -->

    <!-- 考核细则 -->
    <!-- <el-table
      v-if="typeBtnIndex == 2"
      :data="tableData.slice(start, end)"
      style="width: 100%"
      :height="elementHeight"
      @row-dblclick="rowDblclick3"
      @row-click="rowClick"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
      >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column label="岗位" prop="postName" width="120">
      </el-table-column>
      <el-table-column prop="companyName" label="所属公司" width="120">
      </el-table-column>

      <el-table-column
        label="抽取问题数"
        prop="titleCount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未抽题</span>
          <span v-show="scope.row.status === 1">已抽题</span>
          <span v-show="scope.row.status === 2">完成</span>
          <span v-show="scope.row.status === 3">整改完成</span>
          <span v-show="scope.row.status === 4">未完成</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table> -->

    <!-- 自查自纠问题点 -->
    <el-table
      v-if="typeBtnIndex == 1"
      :data="tableData.slice(start, end)"
      :height="elementHeight"
      style="width: 100%"
      @row-click="rowClick"
      @row-dblclick="rowDblclick4"
      :row-style="selectedstyle"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
      id="table"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column prop="companyName" label="公司"> </el-table-column>
      <el-table-column prop="deptName" label="部门" v-if="showDept">
      </el-table-column>
      <el-table-column label="类型" prop="auditType" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.auditType == 0">内审</span>
          <span v-if="scope.row.auditType == 1">外审</span>
        </template>
      </el-table-column>

      <el-table-column
        label="抽取问题数"
        prop="titleCount"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="status" v-show="scope.row.status === 0">
            <div class="statusbox"></div>
            未抽题
          </span>
          <span class="status" v-show="scope.row.status === 1">
            <div style="background: #7fc7fa" class="statusbox"></div>
            已抽题
          </span>
          <span class="status" v-show="scope.row.status === 2"
            ><div class="statusbox1"></div>
            已自纠</span
          >
          <span class="status" v-show="scope.row.status === 3"
            ><div class="statusbox"></div>
            整改完成</span
          >
          <span class="status" v-show="scope.row.status === 4"
            ><div style="background: #f2729c" class="statusbox"></div>
            自纠中</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="completeTime"
        label="完成时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" show-overflow-tooltip>
      </el-table-column>
    </el-table>
    <!-- 混合审核 -->
    <el-table
      v-if="typeBtnIndex == 0"
      :data="tableData.slice(start, end)"
      style="width: 100%"
      :height="elementHeight"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      id="table"
      highlight-current-row
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle"
      @row-dblclick="rowDblclick"
      @row-click="rowClick"
    >
      <el-table-column type="index" label="序号" width="80"> </el-table-column>
      <el-table-column prop="compName" label="被审公司"> </el-table-column>
      <el-table-column prop="deptName" label="部门" v-if="showDept">
      </el-table-column>
      <el-table-column prop="titleCount" label="抽取问题数" width="180">
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" width="180">
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未抽题</span>
          <span v-show="scope.row.status === 1">已抽题</span>
          <span v-show="scope.row.status === 2">完成</span>
          <span v-show="scope.row.status === 3">整改完成</span>
          <span v-show="scope.row.status === 4">未完成</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ scope.row.saveDate | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveName" label="创建人" width="180">
      </el-table-column>
      <el-table-column prop="type" label="抽题项" width="180">
      </el-table-column>
    </el-table>
    <!-- 7s问题点 -->
    <el-table
      v-if="typeBtnIndex == 2"
      :data="tableData.slice(start, end)"
      style="width: 100%"
      :height="elementHeight"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      id="table"
      highlight-current-row
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle"
      @row-dblclick="rowDblclick"
      @row-click="rowClick"
    >
      <el-table-column type="index" label="序号" width="80"> </el-table-column>
      <el-table-column prop="compName" label="被审公司" width="230">
      </el-table-column>
      <el-table-column prop="auditAreaName" label="区域"> </el-table-column>

      <el-table-column prop="titleCount" label="抽取问题数" width="180">
      </el-table-column>
      <el-table-column prop="failCount" label="问题点数" width="180">
      </el-table-column>
      <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未抽题</span>
          <span v-show="scope.row.status === 1">已抽题</span>
          <span v-show="scope.row.status === 2">完成</span>
          <span v-show="scope.row.status === 3">整改完成</span>
          <span v-show="scope.row.status === 4">未完成</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ scope.row.saveTime | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveUserName" label="创建人" width="180">
      </el-table-column>
    </el-table>
    <!-- 风控不符合 -->
    <el-table
      v-if="typeBtnIndex == 3"
      :data="tableData.slice(start, end)"
      style="width: 100%"
      :height="elementHeight"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      id="table"
      highlight-current-row
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle"
      @row-dblclick="rowDblclick"
      @row-click="rowClick"
    >
      <el-table-column type="index" label="序号" width="70"></el-table-column>
      <el-table-column prop="compName" label="被审公司" width="110"></el-table-column>
      <el-table-column prop label="部门">
        <template slot-scope="scope">
          <span>
            {{
            scope.row.deptName ? scope.row.deptName : scope.row.dutyDeptName
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="postName" label="岗位" width="140"></el-table-column>
      <el-table-column prop="titleCount" label="抽取问题数" width="100"></el-table-column>
      <el-table-column prop="failCount" label="问题点数" width="100"></el-table-column>
      <el-table-column prop="status" label="检查状态" width="100">
        <template slot-scope="scope">
          <span v-show="scope.row.status === 0">未抽题</span>
          <span v-show="scope.row.status === 1">已抽题</span>
          <span v-show="scope.row.status === 2">完成</span>
          <span v-show="scope.row.status === 3">整改完成</span>
          <span v-show="scope.row.status === 4">未完成</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveTime" label="创建时间" width="110">
        <template slot-scope="scope">
          <span>{{ scope.row.saveDate | time }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="saveName" label="创建人" width="100"></el-table-column>
      <el-table-column prop="type" label="抽题项" width="150" show-overflow-tooltip></el-table-column>
      <el-table-column prop="score" label="得分" width="100"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      class="_Pagination"
    >
    </el-pagination>
    <Dialog
      title="导出混合审核"
      v-if="typeBtnIndex == 0"
      @handleClose="handleClose"
      width="95%"
      top="3vh"
      :showTowText="true"
      @handleReqData="exportExcel"
      :showDialog="showExcelDialog"
    >
      <el-table
        :data="excelTableData"
        style="width: 100%"
        :height="elementHeight"
        :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
        highlight-current-row
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        id="excelTable"
      >
        <el-table-column type="index" label="序号" width="60">
        </el-table-column>
        <el-table-column prop="compName" label="公司" width="170">
        </el-table-column>
        <el-table-column prop="deptName" label="部门" width="150">
        </el-table-column>
        <el-table-column prop="fileName" label="文件名称" width="180">
        </el-table-column>
        <el-table-column prop="question" label="问题" width="200">
        </el-table-column>
        <el-table-column prop="answer" label="答案" width="200">
        </el-table-column>
        <el-table-column prop="auditMethod" label="审核方式" width="160">
        </el-table-column>
        <el-table-column prop="lawAsk" label="审核意见" width="120">
        </el-table-column>
        <el-table-column prop="mixAuditType" label="审核类型" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.mixAuditType == 0">现场检查</span>
            <span v-if="scope.row.mixAuditType == 1">文件检查</span>
            <span v-if="scope.row.mixAuditType == 2">考核细则</span>
            <span v-if="scope.row.mixAuditType == 3"
              >内外审检查--{{ checkStatus == 1 ? '自纠' : '自查' }}</span
            >
            <span v-if="scope.row.mixAuditType == 4">部门领导审核</span>
          </template>
        </el-table-column>
        <el-table-column prop="violatedTerms" label="违反条款" width="120">
        </el-table-column>
        <el-table-column prop="auditResult" label="审核结果">
          <template slot-scope="scope">
            <div
              v-if="scope.row.mixAuditType != 3 && scope.row.mixAuditType != 4"
            >
              <div v-if="scope.row.auditResult == 1">符合</div>
              <div v-if="scope.row.auditResult == 0">不符合</div>
              <div v-if="scope.row.auditResult == 2">纠正</div>
              <div v-if="scope.row.auditResult == 3">改进</div>
              <!-- //考核细则没有不适用 -->
              <div
                v-if="scope.row.mixAuditType != 2 && scope.row.auditResult == 4"
              >
                不适用
              </div>
            </div>
            <!-- 内外审  checkStatus：0自查，1自纠-->
            <div v-if="scope.row.mixAuditType == 3">
              <div v-if="scope.row.auditResult == 1">
                <span v-if="scope.row.checkStatus == 0">是</span>
                <span v-else>已符合</span>
              </div>
              <div v-if="scope.row.auditResult == 0">
                <span v-if="scope.row.checkStatus == 0">否</span>
                <span v-else>是</span>
              </div>
              <div
                v-if="scope.row.checkStatus == 1 && scope.row.auditResult == 3"
              >
                不合适
              </div>
            </div>
            <!-- 部门领导审核 -->
            <div class="auditResult_box" v-if="scope.row.mixAuditType == 4">
              <div v-if="scope.row.auditResult === 1">记录</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="module" label="模块" width="120">
        </el-table-column>
        <el-table-column prop="correctMeasure" label="措施"> </el-table-column>
        <el-table-column prop="" label="备注" width="200">
          <template slot-scope="scope">
            <span>{{
              scope.row.mixAuditType == 3
                ? scope.row.questionRemark
                : scope.row.question
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="completeTime" label="完成时间" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.completeTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saveUserName" label="创建人" width="80">
        </el-table-column>
        <el-table-column prop="years" label="年份"> </el-table-column>
      </el-table>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '../../components/dialog/Dialog.vue'
import { get } from '../../api/http'
import { exportExcel } from '../../modules/ExportExcel'
export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      typeBtnIndex: 0,
      tableData: [],
      start: 0,
      end: 10,
      curSize: 10,
      currentPage: 1,
      tableRowIndex: null,
      yearsList: [], //年份控制在前后五年
      currentYear: '',
      currentDay1: '',
      currentDay2: '',
      compy: '',
      tableData2: [],
      showLoading: false,
      excelTableData: [],
      showExcelDialog: false,
      showDept: false,
    }
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return ''
      }
      return e.split('T')[0]
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != 'ControlDetial' && from.name != 'MixingDetail') {
      to.meta.ifDoFresh = true
    }
    next()
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false
      this.getTableList()
      this.handleYears()
    } else {
      if (sessionStorage.getItem('controlPageRefsh')) {
        this.tableData.splice(this.tableRowIndex, 1)
        this.tableRowIndex = null
        sessionStorage.removeItem('controlPageRefsh')
      }
    }
    this.getElementHeight()
  },
  mounted() {
  },
  methods: {
    getElementHeight() {
      //innerHeight浏览器窗口的高度
      //offsetTop：距离上层控件的位置
      //最外层嵌套页面(顶部菜单)60,底(分页组件)52,div(main)的滚动条距顶部70
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 52)
      })
    },
    handleYears() {
      var year = new Date().getFullYear()
      var num = year - 5
      for (var i = 0; i < 9; i++) {
        this.yearsList.push((num = num + 1))
      }
    },
    changDay() {
      this.tableData = []
      this.currentPage = 1
      this.start = 0
      this.tableRowIndex = null
      this.tableData2.map((i) => {
        if (
          Date.parse(this.currentDay1) <= Date.parse(i.saveDate) &&
          Date.parse(i.saveDate) <= Date.parse(this.currentDay2) + 86400000
        ) {
          this.tableData.push(i)
        }
        if (
          Date.parse(this.currentDay1) <= Date.parse(i.saveTime) &&
          Date.parse(i.saveTime) <= Date.parse(this.currentDay2) + 86400000
        ) {
          this.tableData.push(i)
        }
      })
    },
    changYear() {
      this.currentDay1 = ''
      this.currentDay2 = ''
      this.changeTypeBtn(this.typeBtnIndex)
    },
    changCompy(e) {
      this.showDept = true
      this.currentDay1 = ''
      this.currentDay2 = ''
      if (!e) return
      this.changeTypeBtn(this.typeBtnIndex)
    },
    changeTypeBtn(index) {
      if (index == 3) {
        this.$router.push('./plan')
        return
      }
      this.showLoading = true
      this.tableRowIndex = null
      this.typeBtnIndex = index
      this.currentPage = 1
      this.start = 0
      this.tableData = []
      this.getTableList()
    },
    // 分页
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.tableRowIndex = null
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    // 获取列表
    getTableList() {
      var url = ''
      switch (this.typeBtnIndex) {
        // case 0:
        //   url = '/api/Supervision/GetAreaList'
        //   break
        // case 1:
        //   url = '/api/Supervision/GetFileAuditList'
        //   break
        // case 2:
        //   url = '/api/Supervision/GetExamineRulesList'
        //   break
        //  现场管理、文件落地、考核细则三个合并改为混合检查
        case 0:
          url =
            '/api/Supervision/GetMixAuditList?CompId=' +
            this.compy +
            '&Year=' +
            this.currentYear
          break
        case 1:
          url =
            '/api/Supervision/GetInOutAuditList?Year=' +
            this.currentYear +
            '&CompId=' +
            this.compy
          break
        case 2:
          url =
            '/api/Supervision/Get7SList?Year=' +
            this.currentYear +
            '&CompId=' +
            this.compy
          break
        case 3:
          this.tableData = [
            {
              compName:'本部',
              deptName:'运营管理部',
              postName:'客服',
              titleCount:'8',
              failCount:'2',
              status:1,
              saveDate:'2024-03-04',
              saveName:'admin',
              type:'风控检查',
              score:'0',
            }
          ]
          break
      }
      this.showLoading = false;
      if(url){
        get(url)
          .then((resp) => {
            if (resp.code == 200) {
              this.tableData = resp.data
              this.tableData2 = JSON.parse(JSON.stringify(resp.data))
            } else {
              this.$message({
                type: 'warning',
                message: resp.message,
              })
            }
            this.showLoading = false
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '系统异常，请稍后再试',
            })
            this.showLoading = false
          })
      }
    },
    // 点击table
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    rowClick(row) {
      this.tableRowIndex = row.index
    },
    // rowDblclick1(row) {
    //   this.$router.push('/ControlDetial?examId=' + row.id + '&pageIndex=1')
    // },
    // rowDblclick2(row) {
    //   this.$router.push('/ControlDetial?examId=' + row.id + '&pageIndex=2')
    // },
    // rowDblclick3(row) {
    //   this.$router.push('/ControlDetial?examId=' + row.id + '&pageIndex=3')
    // },
    rowDblclick(row) {
      // isNoShowDrawBtn判断为是从混合审核还是控制中心页面，控制中心为true
      // show7sTable判断是显示混合问题点表格还是7s
      this.$router.push(
        './mixing-detail?rowItem=' +
          JSON.stringify(row) +
          '&isNoShowDrawBtn=true' +
          (this.typeBtnIndex == 2 ? '&show7sTable=true' : '')
      )
    },
    rowDblclick4(row) {
      this.$router.push('/ControlDetial?examId=' + row.id + '&pageIndex=4')
    },
    // 导出混合审核
    getExcelData() {
      get(
        '/api/Supervision/MixAuditListExport?Year=' +
          this.currentYear +
          '&CompId=' +
          this.compy
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.showExcelDialog = true
            this.excelTableData = resp.data
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('系统异常，请稍后再试')
        })
    },
    exportExcel() {
      exportExcel('#excelTable', '混合审核.xlsx')
      this.showExcelDialog = false
    },
    handleClose() {
      this.showExcelDialog = false
    },
  },
}
</script>

<style lang="less" scoped>
.action {
  background: #5b79ae !important;
  color: #ffffff !important;
}
.indexColor {
  color: #00a2ff;
}
.type-box {
  display: flex;
  padding-bottom: 10px;
  align-items: center;
}
.to-plan {
  color: rgb(4, 132, 251);
  padding: 10px 0;
  font-weight: bold;
  margin-right: 20px;
}
.select-time {
  display: flex;
  align-items: center;
  margin-left: 30px;
  .el-range-editor.el-input__inner {
    padding: 0 10px !important;
  }
  /deep/ .el-range-separator {
    line-height: 28px !important;
  }
}
</style>